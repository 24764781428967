<template>
  <v-form
    v-model.trim="valid"
    @submit.prevent="edit"
  >
    <v-card>
      <v-app-bar
        dark
        flat
        color="primary"
      >
        <v-toolbar-title>Adauga reper</v-toolbar-title>
      </v-app-bar>
      <v-card-text v-show="success">
        <v-row justify="space-around">
          <v-icon
            size="150"
            color="success"
          >
            check_circle
          </v-icon>
        </v-row>
      </v-card-text>
      <v-card-text v-show="!success">
        <v-row>
          <v-col
            class="pa-1"
            cols="12"
            sm="6"
          >
            <v-select
              v-model.trim="form.type"
              @blur="$v.form.type.$touch"
              :error-messages="errors.type"
              :items="types"
              item-value="key"
              item-text="label"
              autocomplete
              readonly
            >
              <template #label>
                <form-label :field="fields.type" />
              </template>
            </v-select>
          </v-col>
          <v-col
            class="pa-1"
            cols="12"
            sm="6"
          >
            <v-text-field
              :value="form.itemNo"
              :error-messages="errors.itemNo"
              readonly
            >
              <template #label>
                <form-label :field="fields.itemNo" />
              </template>
            </v-text-field>
          </v-col>
          <v-col
            class="pa-1"
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model.trim="form.name"
              @blur="$v.form.name.$touch"
              :error-messages="errors.name"
            >
              <template #label>
                <form-label :field="fields.name" />
              </template>
            </v-text-field>
          </v-col>
          <v-col
            class="pa-1"
            cols="8"
            sm="6"
          >
            <v-text-field
              v-model.trim="form.qty"
              @blur="$v.form.qty.$touch"
              :error-messages="errors.qty"
            >
              <template #label>
                <form-label :field="fields.qty" />
              </template>
            </v-text-field>
          </v-col>
          <v-col
            class="pa-1"
            cols="4"
            sm="6"
          >
            <v-text-field
              v-model.trim="form.measureUnit"
              @blur="$v.form.measureUnit.$touch"
              :error-messages="errors.measureUnit"
            >
              <template #label>
                <form-label :field="fields.measureUnit" />
              </template>
            </v-text-field>
          </v-col>
          <v-col
            class="pa-1"
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model.trim="form.unitValue"
              @blur="$v.form.unitValue.$touch"
              :error-messages="errors.unitValue"
            >
              <template #label>
                <form-label :field="fields.unitValue" />
              </template>
            </v-text-field>
          </v-col>
          <v-col
            class="pa-1"
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model.trim="form.location"
              @blur="$v.form.location.$touch"
              :error-messages="errors.location"
            >
              <template #label>
                <form-label :field="fields.location" />
              </template>
            </v-text-field>
          </v-col>
          <v-col
            class="pa-1"
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model.trim="form.address"
              @blur="$v.form.address.$touch"
              :error-messages="errors.address"
            >
              <template #label>
                <form-label :field="fields.address" />
              </template>
            </v-text-field>
          </v-col>
          <v-col
            class="pa-1"
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model.trim="form.description"
              @blur="$v.form.description.$touch"
              :error-messages="errors.description"
            >
              <template #label>
                <form-label :field="fields.description" />
              </template>
            </v-text-field>
          </v-col>
          <v-col
            class="pa-1"
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model.trim="form.account"
              @blur="$v.form.account.$touch"
              :error-messages="errors.account"
            >
              <template #label>
                <form-label :field="fields.account" />
              </template>
            </v-text-field>
          </v-col>
          <v-col
            class="pa-1"
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model.trim="form.classification"
              @blur="$v.form.classification.$touch"
              :error-messages="errors.classification"
            >
              <template #label>
                <form-label :field="fields.description" />
              </template>
            </v-text-field>
          </v-col>
          <v-col
            class="pa-1"
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model.trim="form.classificationName"
              @blur="$v.form.classificationName.$touch"
              :error-messages="errors.classificationName"
            >
              <template #label>
                <form-label :field="fields.classificationName" />
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-show="!success">
        <v-spacer />
        <v-btn :to="{ name: 'active-inventory.scan' }">Anuleaza</v-btn>
        <v-btn
          :loading="submitting"
          color="primary"
          type="submit"
        >
          <v-icon>save</v-icon>
          Salveaza
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script lang="ts">
import api from '@/api';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import makeValidator from '@/lib/validator';
import { defineComponent } from 'vue';

const types = [
  {
    key: 'oi',
    label: 'Obiect de inventar',
  },
  {
    key: 'mf',
    label: 'Mijloc fix',
  },
];

const parseCode = (code: any) => {
  // TODO upgrade to scan temporary items
  const p = code[0].toLowerCase();
  const type = types.find((type) => type.key[0] === p);
  if (!type) {
    throw new Error('Unknown code');
  }
  const itemNo = code.substr(1);

  return {
    type,
    itemNo,
  };
};

export default defineComponent({
  name: 'CreateScripticItem',
  mixins: [validationMixin],
  props: {
    node: {
      type: Object,
      required: true,
    },
    code: {
      type: String,
      required: true,
    },
  },
  data() {
    const { type, itemNo } = this.code ? parseCode(this.code) : { type: types[0].key, itemNo: '' };

    const fields = {
      type: {
        label: 'Tip',
        default: type,
        cast: ({ key }: any) => key,
        rules: { required },
      },
      itemNo: {
        label: 'Nr. inventar',
        default: itemNo,
        rules: { required },
      },
      name: {
        label: 'Denumire',
        default: '',
        rules: { required },
      },
      measureUnit: {
        label: 'UM',
        default: '',
        rules: {},
      },
      qty: {
        label: '#',
        default: '1',
        rules: {},
      },
      unitValue: {
        label: 'Val',
        default: '',
        rules: {},
      },
      location: {
        label: 'Locatie',
        default: '',
        rules: {},
      },
      address: {
        label: 'Adresa',
        default: '',
        rules: {},
      },
      description: {
        label: 'Descriere',
        default: '',
        rules: {},
      },
      account: {
        label: 'Cont',
        default: '',
        rules: {},
      },
      classification: {
        label: 'Clasificare',
        default: '',
        rules: {},
      },
      classificationName: {
        label: 'Nume clasificare',
        default: '',
        rules: {},
      },
    };
    return {
      show: false,
      submitting: false,
      valid: true,
      success: false,
      backendErrors: undefined,
      form: Object.entries(fields).reduce(
        (carry, [field, { default: value }]: any) => ({ ...carry, [field]: value }),
        {},
      ),
      types,
      fields,
    };
  },
  validations() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.validator.validator();
  },
  computed: {
    validator() {
      return makeValidator(this.fields, 'form');
    },
    errors() {
      return this.validator.errors(this.$v, this.backendErrors);
    },
    inventory() {
      return this.$store.state.activeInventory.inventory;
    },
  },
  watch: {
    show(v) {
      if (!v) setTimeout(() => this.$emit('close'), 300);
    },
  },
  mounted() {
    this.show = true;
  },
  methods: {
    setSuccess(data: any) {
      this.success = true;
      this.$msg({
        color: 'success',
        title: 'Reperul a fost adaugat cu succes.',
      });
      this.$router.push({ name: 'active-inventory.scan' });
    },
    edit() {
      this.$v.$touch();
      if (this.$v.form.$invalid) {
        return Promise.resolve();
      }
      this.submitting = true;
      const body = {
        ...this.validator.values(this.$v),
        nodeId: this.node.id,
        suffix: 1,
      };

      this.backendErrors = undefined;
      const params = {
        inventoryId: this.inventory._id,
      };

      return api.inventory.scriptic.items
        .create({ body, params })
        .then((response) =>
          response.json().then((payload) => {
            if (response.ok) {
              this.setSuccess(payload.data);
            } else if (response.status === 422) {
              this.backendErrors = payload.errors;
            } else {
              return Promise.reject(new Error(payload.error));
            }
          }),
        )
        .catch((e) => this.$msg(e))
        .finally(() => {
          this.submitting = false;
        });
    },
  },
});
</script>
