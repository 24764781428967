export const radiansToDegrees = (radians: any) => (radians * 180) / Math.PI;
export const degreesToRadians = (degrees: any) => (degrees * Math.PI) / 180;

export const vectorToCoordinate = ([x, y, z]: any) => [Math.atan2(z, Math.sqrt(x * x + y * y)), Math.atan2(y, x)];

export const coordinateToVector = ([lat, lng]: any) => [
  Math.cos(lat) * Math.cos(lng),
  Math.cos(lat) * Math.sin(lng),
  Math.sin(lat),
];

export const calculateVectorsCenter = (vectors: any) =>
  vectors
    .reduce(([X, Y, Z]: any, [x, y, z]: any) => [X + x, Y + y, Z + z], [0.0, 0.0, 0.0])
    .map((c: any) => c / vectors.length);

export const getPolygonCenter = (coordinates: any) =>
  vectorToCoordinate(
    calculateVectorsCenter(
      coordinates.map((coordinates: any) => coordinateToVector(coordinates.map(degreesToRadians))),
    ),
  ).map(radiansToDegrees);

export const bounds = {
  parse: (s: any) =>
    s
      .split(';')
      .map((pair: any) =>
        pair
          .split(',')
          .filter((a: any) => !!a)
          .map(parseFloat),
      )
      .filter((pair: any) => pair.length === 2 && pair.every((a: any) => !isNaN(a))),
  stringify: (coordinates: any) => coordinates.map((c: any) => c.join(', ')).join('; '),
};
