import makeApi from '@/lib/api';
import store from '@/store';

/* eslint-disable quote-props */
const api = makeApi(process.env.VUE_APP_INVENTORY_HOST, () => ({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Authorization: store.state.token === null ? undefined : 'Bearer ' + store.state.auth.token,
}));
/* eslint-enable quote-props */

export default {
  auth: {
    login: api.post('/auth/login', { credentials: true }),
    logout: api.post('/auth/logout', { credentials: true }),
    refresh: api.post('/auth/refresh', { credentials: true }),
  },
  access: {
    token: api.post('/access'),
  },
  institutions: {
    index: api.get('/institutions'),
    show: api.get('/institutions/:institutionId'),
  },
  inventory: {
    active: api.get('/inventory/active'),
    orgChart: api.get('/inventory/:inventoryId/org-chart/nodes/tree'),
    scriptic: {
      nodeItems: api.get('/inventory/:inventoryId/scriptic/node/:nodeId/items'),
      photos: {
        upload: api.post('/inventory/:inventoryId/scriptic/items/:itemId/photos', { asJSON: false }),
      },
      items: {
        index: api.get('/inventory/:inventoryId/scriptic/items'),
        create: api.post('/inventory/:inventoryId/scriptic/items'),
        show: api.get('/inventory/:inventoryId/scriptic/items/:itemId'),
        edit: api.put('/inventory/:inventoryId/scriptic/items/:itemId'),
        delete: api.delete('/inventory/:inventoryId/scriptic/items/:itemId'),
      },
    },
    scan: {
      find: api.post('/inventory/:inventoryId/scan/find'),
      confirm: api.post('/inventory/:inventoryId/scan/confirm'),
    },
  },
  counties: {
    index: api.get('/counties'),
    find: api.post('/counties/find'),
    localities: {
      index: api.get('/counties/:code/localities'),
    },
  },
};
