import Node from '@/pages/active-inventory/scan/Node.vue';
import ScanCode from '@/pages/active-inventory/scan/ScanCode.vue';
import confirm from '@/pages/active-inventory/scan/confirm/routes';
import CreateItem from '@/pages/active-inventory/scan/CreateItem.vue';

export default {
  path: 'scan/:nodeId',
  props: true,
  component: Node,
  children: [
    {
      path: '',
      name: 'active-inventory.scan',
      props: true,
      component: ScanCode,
    },
    {
      path: 'create/:code',
      name: 'active-inventory.create',
      props: true,
      component: CreateItem,
    },
    confirm,
  ],
};
