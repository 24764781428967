export const methods = Object.freeze(['GET', 'HEAD', 'OPTIONS', 'PUT', 'PATCH', 'POST', 'DELETE']);
export const safeMethods = Object.freeze(['GET', 'HEAD', 'OPTIONS']);

export const buildListParam = (param: any, value: any) =>
  value.map((item: any, index: any) => `${encodeURIComponent(param)}[${index}]=${encodeURIComponent(value)}`).join('&');

export const buildParam = ([param, value]: any) =>
  Array.isArray(value) ? buildListParam(param, value) : encodeURIComponent(param) + '=' + encodeURIComponent(value);

export const buildQueryString = (params: any) => Object.entries(params).map(buildParam).join('&');

export const trimTrailingSlash = (s: any) => s.replace(/^\?$/, '');
