export default {
  namespaced: true,
  state: {
    deps: [],
  },
  getters: {
    deps: (state: any) =>
      state.deps.reduce(
        (carry: any, { dep, value }: any) => ({
          ...carry,
          [dep]: value,
        }),
        {},
      ),
  },
  mutations: {
    set: (state: any, what: any) => {
      const idx = state.deps.findIndex((d: any) => d.dep === what.dep);
      if (idx > -1) {
        state.deps.splice(idx, 1, what);
      } else {
        state.deps.push(what);
      }
    },
  },
};
