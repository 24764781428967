<template>
  <v-alert
    v-bind="$attrs"
    :icon="icon"
    :type="type"
    border="left"
    class="mb-2 mt-2"
    data-cy="alert"
  >
    <h3 class="font-weight-medium text-left">
      <slot />
    </h3>
  </v-alert>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UvAlertIcon',
  props: {
    type: {
      type: String,
      default: 'info',
    },
  },
  computed: {
    icon() {
      if (this.type === 'warning') {
        return 'warning';
      } else if (this.type === 'error') {
        return 'error';
      } else if (this.type === 'success') {
        return 'check_circle';
      }

      return 'info';
    },
  },
});
</script>
