export const module = {
  namespaced: true,
  state: {
    ready: false,
    location: null,
    locating: false,
    lastUpdated: null,
    lastRequested: null,
    error: null,
  },
  mutations: {
    setReady: (state: any) => {
      state.ready = true;
    },
    set: (state: any, location: any) => {
      state.location = Object.freeze({
        coords: Object.freeze({
          latitude: location.coords.latitude,
          longitude: location.coords.longitude,
          accuracy: parseInt(location.coords.accuracy),
        }),
        timestamp: location.timestamp,
      });
      state.locating = false;
    },
    setError: (state: any, error: any) => {
      state.locating = false;
      state.error = error;
    },
    request: (state: any) => {
      if (state.locating === true) {
        return;
      }
      state.locating = true;
      state.error = null;
      state.lastRequested = new Date().getTime();
    },
  },
};

export const plugin = (store: any) => {
  document.addEventListener('deviceready', () => store.commit('location/setReady'));

  const locate = () =>
    navigator.geolocation.getCurrentPosition(
      (location) => store.commit('location/set', location),
      (e) => store.commit('location/setError', e),
      { maximumAge: 3000, timeout: 5000, enableHighAccuracy: true },
    );

  const subscriptions = [['location/request', [locate]]];
  const findSubscription = (type: any) => (subscriptions.find(([t]) => t === type) || [null, []])[1];

  store.subscribe(({ type, payload }: any, state: any) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    findSubscription(type).forEach((handler) => handler(payload, state)),
  );
};
