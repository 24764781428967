import { helpers } from 'vuelidate/lib/validators';
import { pointInPolygon } from 'geojson-utils-ts';
import { Marker, Polygon } from 'leaflet';
import { filter, identity, map, pipe, reduce } from 'rambda';

const sameAsFields = {
  password: 'Parola',
};

const errors = {
  required: (label: any) => `Campul ${label} este obligatoriu`,
  numeric: (label: any) => `Campul ${label} trebuie sa fie de tip numeric`,
  integer: (label: any) => `Campul ${label} trebuie sa fie de tip numar intreg`,
  email: (label: any) => `Campul ${label} nu este o adresa de e-mail valida`,
  minLength: (label: any, { min }: any) => `Campul ${label} trebuie sa aibe cel putin ${min} caractere`,
  minValue: (label: any, { min }: any) => `Campul ${label} trebuie sa aibe o valoare minima de ${min}`,
  sameAs: (label: any, { eq }: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const sameAs = sameAsFields[eq] || 'Field';
    return `Campurile ${label} si ${sameAs} trebuie sa fie identice`;
  },
  postalCode: (label: any) => `Campul ${label} are un format invalid`,
  location: (label: any) => `Campul ${label} are un format invalid (45.3, 25.6)`,
  locationBounds: (label: any) => `Campul ${label} are un format invalid (x1, y1; x2, y2; x3, y3;...)`,
  locationWithinBounds: (label: any) => `Coordonatele din campul ${label} nu se afla in permetrul prestabilit`,
};

const errorMessage = (label: any, rule: any, $field: any) =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  errors.hasOwnProperty(rule) ? errors[rule](label, $field.$params[rule]) : `${label} failed: ${rule}`;

const fieldRules = (rules: any) =>
  Object.entries(rules).reduce(
    (carry, [name, rule]) =>
      Object.defineProperty(carry, name, {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value: rule.hasOwnProperty('rule') ? rule.rule : rule,
        enumerable: true,
      }),
    {},
  );

const makeValidator = (fields: any, form: any) => {
  const validator = () => ({
    [form]: Object.entries(fields).reduce((carry, [field, { rules }]: any) => {
      const value = fieldRules(rules);
      return Object.defineProperty(carry, field, {
        value,
        enumerable: true,
      });
    }, {}),
  });

  const rulesFailed = (config: any) =>
    config.$anyError
      ? Object.entries(config)
          .filter(([rule, value]) => rule[0] !== '$' && value === false)
          .map(([rule]) => rule)
      : [];

  const errors = ($v: any, errors: any) =>
    pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      map(([field, config]) => [field, config, $v[form][field]]),
      reduce(
        (carry, [field, config, validation]) =>
          Object.defineProperty(carry, field, {
            value:
              errors && errors.hasOwnProperty(field)
                ? errors[field]
                : rulesFailed(validation).map((rule) => errorMessage(config.label, rule, validation)),
            enumerable: true,
          }),
        {},
      ),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    )(Object.entries(fields));

  const values = ($v: any) =>
    pipe(
      filter(([field, config]) => config.rules.hasOwnProperty('required') || $v[form][field].$model !== ''),
      map(([field, config]) => [
        field,
        config.hasOwnProperty('cast') && config.cast instanceof Function ? config.cast : identity,
      ]),
      reduce(
        (carry, [field, cast]) =>
          Object.defineProperty(carry, field, {
            value: cast($v[form][field].$model),
            enumerable: true,
          }),
        {},
      ),
    )(Object.entries(fields));

  const labels = (_: any) =>
    Object.entries(fields).reduce(
      (carry, [field, config]) => ({
        ...carry,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [field]: config.label + (config.rules.hasOwnProperty('required') ? ' *' : ''),
      }),
      {},
    );

  return {
    validator,
    values,
    errors,
    labels,
  };
};

export const formGenerator = (fields: any) =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Object.entries(fields).reduce((carry, [field, { default: value }]) => ({ ...carry, [field]: value }), {});

const coordinatesRegexp =
  '[-+]?([1-8]?\\d(\\.\\d+)?|90(\\.0+)?),\\s*[-+]?(180(\\.0+)?|((1[0-7]\\d)|([1-9]?\\d))(\\.\\d+)?)';

const locationRegexp = new RegExp(`^${coordinatesRegexp}$`);

export const validations = {
  postalCode: helpers.regex('postalCode', /^\d{6}$/),
  location: helpers.regex('location', locationRegexp),
  locationBounds: helpers.regex(
    'locationBounds',
    new RegExp(`^${coordinatesRegexp}(;\\s*${coordinatesRegexp}){2,};?$`),
  ),
  locationWithinBounds: (bounds: any) => (value: any) => {
    const matches = locationRegexp.exec(value);
    if (!matches) {
      return true; // others should validate
    }
    return pointInPolygon(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      new Marker([matches[1], matches[4]]).toGeoJSON().geometry,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      new Polygon(bounds).toGeoJSON().geometry,
    );
  },
};

export default makeValidator;
