import { Module } from 'vuex';
import { RootState, SystemState } from '@/store/definitions';

export const module: Module<SystemState, RootState> = {
  namespaced: true,
  state: {},
  actions: {
    start: async () => {},
  },
};
