<template>
  <router-view
    v-bind="$props"
    :node="node"
  />
</template>
<script lang="ts">
import { computed, inject, watch, defineComponent } from 'vue';

export default defineComponent({
  props: {
    nodeId: { type: String, required: true },
  },
  setup(props) {
    const store = inject('store');
    const router = inject('router');
    const pageDeps = inject('pageDeps');

    const node = computed(() =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      store.state.activeInventory.inventory.organizationalChart.nodes.find((node) => node.id === props.nodeId),
    );

    if (!node.value) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      router.back();
    }

    watch(
      () => node,
      (node) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pageDeps('selectedNode', node.value);
      },
      { immediate: true },
    );

    return { node };
  },
});
</script>
