<template>
  <div>
    <slot name="title" />
    <v-row no-gutters>
      <v-col
        v-for="[label, data] in details"
        :key="label"
        md="3"
        sm="4"
        cols="6"
      >
        <div class="py-2">
          <h4>{{ label }}</h4>
          <div>{{ data }}</div>
        </div>
      </v-col>
    </v-row>
    <div
      v-if="item.importAsQty"
      class="py-0"
    >
      <v-divider />
      <div class="d-flex align-center my-3">
        <v-btn
          @click="showQuantities = !showQuantities"
          icon
        >
          <v-icon>{{ showQuantities ? 'expand_less' : 'expand_more' }}</v-icon>
        </v-btn>
        <h3>Cantitati</h3>
      </div>
      <v-expand-transition>
        <v-data-table
          v-if="showQuantities"
          v-bind="quantities"
        />
      </v-expand-transition>
      <v-divider />
      <div class="d-flex align-center my-3">
        <v-btn
          @click="showScans = !showScans"
          icon
        >
          <v-icon>{{ showScans ? 'expand_less' : 'expand_more' }}</v-icon>
        </v-btn>
        <h3>Scanari</h3>
      </div>
      <v-expand-transition>
        <v-data-table
          v-if="showScans"
          v-bind="scans"
        />
      </v-expand-transition>
    </div>
    <slot name="footer" />
  </div>
</template>
<script lang="ts">
import { computed, ref, defineComponent } from 'vue';
import makeQuantities from '@/pages/active-inventory/scan/confirm/previewQuantities';
import { formatCurrency } from '@/lib/filters';

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const qtyDetails = computed(() => [
      ['Cantitate totala', props.item.qty + ' ' + props.item.measureUnit],
      ['Valoare totala', formatCurrency(props.item.totalValue)],
    ]);

    const uniqueDetails = computed(() => [
      ['Cantitate', props.item.quantities[0].qty + ' ' + props.item.measureUnit],
      ['Valoare unitara', formatCurrency(props.item.quantities[0].unitValue)],
    ]);

    const showQuantities = ref(false);
    const showScans = ref(false);

    const scans = computed(() => ({
      headers: [
        {
          text: 'Nod organigrama',
          value: 'nodeLabel',
        },
        {
          text: 'Data',
          value: 'scannedAt',
        },
        {
          text: 'Cantitate',
          value: 'qty',
        },
      ],
      items: props.item.scan,
      hideDefaultFooter: true,
    }));

    const { quantities } = makeQuantities(props);

    return {
      showQuantities,
      showScans,
      details: computed(() => [
        ['Tip', props.item.type],
        ['Descriere', props.item.description || '-'],
        ['Numar', props.item.itemCode],
        ...(props.item.importAsQty ? qtyDetails.value : uniqueDetails.value),
        ['Adresa', props.item.address || '-'],
        ['Cont', props.item.account || '-'],
        ['Clasificare', props.item.classification || '-'],
        ['Nume clasificare', props.item.classificationName || '-'],
      ]),
      addQuantityRoute: computed(() => ({
        name: 'inventory.scriptic.quantities.create',
        params: {
          itemId: props.item._id,
        },
      })),
      quantities,
      scans,
    };
  },
});
</script>
