export const flattenNodes = (nodes: any) =>
  nodes.reduce((carry: any, node: any) => carry.concat([node], flattenNodes(node.children)), []);
export const flattenNode = (node: any) => [node].concat(flattenNodes(node.children));
export const isChildOf = (newParent: any, nodes: any) =>
  nodes.some((child: any) => child === newParent || isChildOf(newParent, child.children));

export const makeNode = (nodeData: any, tree: any, parent: any, depth = 1) => {
  const node = {
    ...nodeData,
    parent,
    tree,
    depth,
    children: [],
  };

  if (nodeData.children) {
    node.children = prepNodes(nodeData.children, tree, node, depth + 1);
  }
  return node;
};

export const prepNodes = (nodes: any, tree: any, parent = null, depth = 0) =>
  nodes.map((props: any) => makeNode(props, tree, parent, depth));

export const prepTree = ({ tree, ...treeData }: any) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  treeData.tree = prepNodes(tree);
  treeData.nodes = flattenNodes(tree);

  return treeData;
};
