import { formatCurrency } from '@/lib/filters';
import { computed } from 'vue';

export default (props: any) => {
  const editQuantity = (_id: any) => ({
    label: 'Modifica',
    icon: 'edit',
    route: {
      name: 'inventory.scriptic.quantities.edit',
      params: { itemId: props.item._id, quantityId: _id },
    },
  });
  const quantities = computed(() => ({
    headers: [
      {
        text: 'Denumire',
        value: 'name',
      },
      {
        text: 'Cantitate',
        value: 'qty',
      },
      {
        text: 'Valoare',
        value: 'unitValue',
        align: 'right',
      },
      {
        text: 'Locatie',
        value: 'location',
      },
      // {
      //   text: 'Scan',
      //   value: 'scans',
      //   align: 'right',
      //   sortable: false
      // },
      props.editable
        ? {
            text: '',
            value: 'actions',
            sortable: false,
          }
        : undefined,
    ].filter((v) => v !== undefined),
    items: props.item.quantities.map(({ _id, location, name, qty, unitValue }: any) => ({
      location,
      name,
      qty,
      // scans: 0,
      unitValue: formatCurrency(unitValue),
      actions: [editQuantity(_id)],
    })),
    hideDefaultFooter: true,
  }));

  return { quantities };
};
