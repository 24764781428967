import Login from '@/pages/Login.vue';
import Layout from '@/views/Layout.vue';
import home from '@/pages/home/routes';
import activeInventory from '@/pages/active-inventory/routes';
import NotFound from '@/views/NotFound.vue';

export default [
  {
    path: '/login',
    name: 'login',
    meta: { auth: false },
    component: Login,
  },
  {
    path: '/',
    component: Layout,
    children: [
      home,
      activeInventory,
      {
        path: '*',
        component: NotFound,
      },
    ],
  },
];
