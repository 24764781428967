import { isString } from '@/lib/tools';

const props = ['title', 'color'];
const fns = [
  (e: any) =>
    e instanceof SyntaxError
      ? {
          color: 'error',
          title: 'Ceva nu functioneaza...',
          timeout: 600000,
          body: e.message,
        }
      : e,
  (e: any) =>
    e instanceof Error
      ? {
          color: 'error',
          title: e.message,
          timeout: 6000,
        }
      : e,
  (title: any) =>
    isString(title)
      ? {
          color: 'info',
          title,
        }
      : title,
  (msg: any) =>
    props.every((prop) => msg.hasOwnProperty(prop))
      ? msg
      : {
          color: 'error',
          title: `Message must contain props ${props.join(', ')}.`,
          body: JSON.stringify(msg),
        },
];

export default {
  namespaced: true,
  state: {
    list: [],
  },
  getters: {
    count: (state: any) => state.list.length,
    head: (state: any) => state.list[0],
  },
  mutations: {
    push: (state: any, message: any) => {
      state.list.push(message);
    },
    shift: (state: any) => {
      state.list.shift();
    },
  },
  actions: {
    shift: ({ commit }: any) => {
      commit('shift');
    },
    push: ({ commit }: any, msg: any) => {
      if (process.env.VUE_APP_DEBUG) {
        console.log(msg);
      }
      commit('push', {
        ...fns.reduce((prev, fn) => fn(prev), msg),
        timestamp: new Date().getTime(),
      });
    },
  },
};
