<template>
  <v-app>
    <v-app-bar
      app
      clipped-left="clipped-left"
      dense
      fixed
    >
      <v-toolbar-title class="d-flex">
        <router-link :to="{ name: 'home' }">
          <img
            :style="logoStyle"
            src="@/assets/logo.png"
            class="d-block"
          />
        </router-link>
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn
          @click="logout"
          color="error darken-1"
          data-cy="logout-btn"
          icon
          text
        >
          <v-icon>exit_to_app</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>
    <v-main>
      <v-container :fluid="wide">
        <router-view />
        <pwa-prompt />
      </v-container>
    </v-main>
    <messages-list store="messages" />
  </v-app>
</template>
<script lang="ts">
import MessagesList from '../components/MessagesList.vue';
import { computed, inject, defineComponent } from 'vue';
import PwaPrompt from '@/components/PwaPrompt.vue';

export default defineComponent({
  components: {
    MessagesList,
    PwaPrompt,
  },
  setup() {
    const store = inject('store');
    const router = inject('router');

    // const user = computed(() => store.state.auth.user);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const wide = computed(() => router.currentRoute.matched.some((route) => !!route.meta.wide));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const logout = () => store.dispatch('auth/logout');

    const logoStyle = computed(() => ({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter: store.state.darkTheme ? 'invert(100%)' : 'none',
    }));

    return { logout, wide, logoStyle };
  },
});
</script>
