<template>
  <v-card width="100%">
    <v-app-bar
      flat
      dark
      color="primary"
    >
      <h3 class="text-h5">Pozele reperului</h3>
      <v-spacer />
      <v-btn
        @click="takePhoto"
        small
        color="success"
        :loading="upload.submitting"
      >
        <v-icon
          small
          left
        >
          add_a_photo
        </v-icon>
        <span>Adauga</span>
      </v-btn>
    </v-app-bar>
    <v-card-text>
      <input
        @change="($event) => handleFiles($event.target.files)"
        ref="takePhotoRef"
        type="file"
        accept="image/*"
        style="display: none"
      >
      <v-alert
        v-if="photos.length === 0"
        color="warning"
      >
        <div>Nu exista poze</div>
      </v-alert>
      <v-carousel v-else>
        <v-carousel-item
          v-for="(photo, k) in photos"
          :key="k"
          class="contained-background"
          :src="photo.url"
        >
          <div class="photo-description">
            {{ photo.meta.inventory.name }} - {{ photo.meta.inventory.year }} -
            {{ photo._id }}
          </div>
        </v-carousel-item>
      </v-carousel>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        :to="{ name: 'active-inventory.scan' }"
        text
      >
        Finalizeaza
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import api from '@/api';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'InventoryItemPhotos',
  props: {
    scripticItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      uploadedPhotos: [],
      upload: {
        submitting: false,
        error: null,
      },
      error: '',
    };
  },
  computed: {
    inventory() {
      return this.$store.state.activeInventory.inventory;
    },
    params() {
      return {
        inventoryId: this.inventory._id,
        itemId: this.scripticItem._id,
      };
    },
    photos() {
      return this.uploadedPhotos.concat(this.scripticItem.photos.slice().reverse());
    },
  },
  methods: {
    takePhoto() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$refs.takePhotoRef.click();
    },
    /**
     * @param files {FileList}
     * @returns Promise
     */
    async handleFiles(files: any) {
      try {
        if (files.length === 0) {
          throw new Error('Nu ai ales niciun fisier.');
        }
        if (files.length > 1) {
          throw new Error('Cate o poza pe rand.');
        }

        const file = files[0];

        const blob = await new Promise((resolve, reject: any) => {
          if (!file.type.startsWith('image/')) {
            return reject(new Error('Alege o imagine sau poza.'));
          }
          const reader = new FileReader();
          reader.onloadend = function () {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            resolve(new Blob([this.result], { type: file.type }));
          };
          reader.onerror = reject;
          reader.readAsArrayBuffer(file);
        });
        return this.uploadPhoto(blob);
      } catch (e) {
        this.$msg(e);
      }
    },
    addUploadedPhoto(data: any) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.uploadedPhotos.unshift(data);
    },
    uploadPhoto(file: any) {
      this.upload.submitting = true;
      this.upload.error = null;
      const body = new FormData();
      // const reader = new FileReader();
      body.append('file', file, 'photo.jpg');

      return api.inventory.scriptic.photos
        .upload({
          params: this.params,
          body,
        })
        .then((response) =>
          response
            .json()
            .then((payload) =>
              response.ok ? this.addUploadedPhoto(payload.data) : Promise.reject(new Error(payload.error)),
            ),
        )
        .catch((e) => {
          this.upload.error = e;
          return Promise.reject(e);
        })
        .finally(() => {
          this.upload.submitting = false;
        });
    },
  },
});
</script>
