import Main from '@/pages/active-inventory/Main.vue';
import Show from '@/pages/active-inventory/Show.vue';
import scan from '@/pages/active-inventory/scan/routes';

export default {
  path: 'active-inventory',
  props: true,
  component: Main,
  meta: {
    auth: true,
  },
  children: [
    {
      path: '',
      name: 'active-inventory.show',
      component: Show,
    },
    scan,
  ],
};
