<template>
  <div>
    <uv-alert-icon
      v-if="error"
      type="error"
    >
      {{ error.message }}
    </uv-alert-icon>
    <v-list class="d-flex">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{ inventory.year }}</v-list-item-title>
          <v-list-item-subtitle class="pb-0">Anul</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider vertical />
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{ formatDateAlt(inventory.startDate) }}</v-list-item-title>
          <v-list-item-subtitle class="pb-0">Data start</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider vertical />
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{ formatDateAlt(inventory.createdAt) }}</v-list-item-title>
          <v-list-item-subtitle class="pb-0">Creat la</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider />
    <h2 class="mt-2">Organigrama</h2>
    <div v-if="hasOrgChart">
      <p><small>Alege un compartiment pentru a incepe scanarea.</small></p>
      <v-treeview
        :items="inventory.organizationalChart.tree"
        item-text="label"
        item-key="id"
        active.sync="_active"
        return-object="return-object"
        open-on-click
      >
        <template #label="{ item }">
          <div
            v-if="item.children.length > 0"
            class="v-treeview-node__label"
          >
            {{ item.label }}
          </div>
          <div
            v-else
            class="v-treeview-node__label"
          >
            <router-link :to="nodeRoute(item)">
              {{ item.label }}
            </router-link>
          </div>
        </template>
      </v-treeview>
    </div>
    <uv-alert-icon
      v-else
      type="warning"
    >
      Organigrama nu este finalizata.
      <br >
      Contacteaza administratorul institutiei.
    </uv-alert-icon>
    <router-view />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'InventoryShow',
  inject: ['formatDateAlt'],
  data() {
    return {
      submitting: false,
    };
  },
  computed: {
    error() {
      return this.$store.state.activeInventory.error;
    },
    inventory() {
      return this.$store.state.activeInventory.inventory;
    },
    hasOrgChart() {
      return this.inventory.organizationalChart;
    },
  },
  methods: {
    nodeRoute(node: any) {
      return {
        name: 'active-inventory.scan',
        params: {
          nodeId: node.id,
        },
      };
    },
    finish() {
      this.$router.push({
        name: 'active-inventory.org-chart',
        params: {
          inventoryId: this.inventory._id,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          key: new Date().getTime(),
        },
      });
    },
  },
});
</script>
