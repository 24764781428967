import { parse, tokensToFunction } from 'path-to-regexp';
import { pipe } from 'rambda';
import { buildQueryString, methods, safeMethods, trimTrailingSlash } from '@/lib/url';

export const makeFullUrl = (host: any, url: any) => host + (url.length > 0 && url[0] !== '/' ? '/' + url : url);

const makeMethodRoute =
  (method: any, rootUrl: any, makeHeaders: any) =>
  (route = '', { asJSON = true, acceptJSON = true, credentials = false } = {}) => {
    const makeUrl = makeFullUrl.bind(undefined, rootUrl);
    const tokens = parse(route);
    const toPath = tokensToFunction(tokens);
    const variableTokenNames = tokens
      .filter((token) => typeof token !== 'string')
      .map(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (token) => token.name,
      );
    const onlyQueryStringTokens = (item: any) =>
      item.filter(([param]: any) => param !== undefined && !variableTokenNames.includes(param));

    const url = (params: any) =>
      [
        [toPath, makeUrl, trimTrailingSlash],
        [Object.entries, onlyQueryStringTokens, Object.fromEntries, buildQueryString],
      ]
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .map((fns) => pipe(...fns)(params))
        .filter((a) => a)
        .join('?');

    const fn = ({ body, headers = {}, params = {} }: any = {}) =>
      fetch(url(params || {}), {
        method,
        credentials: credentials ? 'include' : 'omit',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        body: safeMethods.includes(method) ? undefined : asJSON ? JSON.stringify(body) : body,
        headers: makeHeaders(headers, { asJSON, acceptJSON }),
      });

    fn.url = url;

    return fn;
  };

/* eslint-disable quote-props */
const makeDefaultHeaders =
  (makeRootHeaders: any) =>
  (headers: any, { asJSON, acceptJSON }: any) => ({
    ...(asJSON ? { 'Content-Type': 'application/json' } : {}),
    ...(acceptJSON ? { Accept: 'application/json' } : {}),
    ...makeRootHeaders(),
    ...headers,
  });
/* eslint-enable quote-props */

export default (rootUrl: any, makeRootHeaders: any) =>
  Object.fromEntries(
    methods.map((method) => [
      method.toLowerCase(),
      makeMethodRoute(method, rootUrl, makeDefaultHeaders(makeRootHeaders)),
    ]),
  );
