<template>
  <div>
    <v-app-bar
      flat
      dark
      color="primary"
    >
      <v-btn
        :to="{ name: 'active-inventory.scan' }"
        exact
        icon
      >
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <h3 class="red--text">{{ node.label }}</h3>
    </v-app-bar>
    <v-app-bar
      flat
      dark
      color="grey darken-4"
    >
      <h3>{{ scripticItem.name }}</h3>
    </v-app-bar>
    <scriptic-item-preview :item="scripticItem" />
    <v-alert
      v-if="scripticItem.photos.length === 0"
      type="info"
      icon="warning"
    >
      Nu exista poze
    </v-alert>
    <template v-else>
      <v-divider />
      <div class="d-flex align-center my-3">
        <v-btn
          v-if="scripticItem.photos.length > 0"
          @click="showPhotos = !showPhotos"
          icon
        >
          <v-icon>{{ showPhotos ? 'expand_less' : 'expand_more' }}</v-icon>
        </v-btn>
        <h3>Poze</h3>
      </div>
      <v-expand-transition>
        <v-card-text v-if="showPhotos">
          <v-carousel>
            <v-carousel-item
              v-for="photo in scripticItem.photos"
              :key="photo._id"
              class="contained-background"
              :src="photo.url"
            >
              <div
                v-if="'inventory' in photo.meta"
                class="photo-description"
              >
                {{ photo.meta.inventory.name }} - {{ photo.meta.inventory.year }}
              </div>
            </v-carousel-item>
          </v-carousel>
        </v-card-text>
      </v-expand-transition>
      <v-divider />
    </template>
    <v-card-text>
      <v-switch
        v-model="location.active"
        class="mx-0 my-1 pa-0"
        label="Locatie"
        hide-details="hide-details"
      />
    </v-card-text>
    <v-expand-transition>
      <scriptic-item-location
        v-if="location.active"
        v-model="location.location"
        :bounds="inventory.institution.address.bounds"
        :scriptic-item="scripticItem"
      />
    </v-expand-transition>
    <v-divider />
    <v-card-text v-if="isQty">
      <v-text-field
        v-model.trim="form.qty"
        :rules="fields.qty.rules"
        :error-messages="errors.qty"
        :label="fields.qty.label"
        required
      />
    </v-card-text>
    <v-card-text v-if="!isQty || scripticItem.type === 'mf'">
      <v-select
        v-model.trim="form.condition"
        :error-messages="errors.condition"
        :label="fields.condition.label"
        :items="fields.condition.items"
        clearable
      />
      <v-textarea
        v-model.trim="form.conditionDescription"
        :rules="fields.conditionDescription.rules"
        :error-messages="errors.conditionDescription"
        :label="fields.conditionDescription.label"
        :disabled="form.condition === null"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn @click="confirmWithPhotos">Confirma si pozeaza</v-btn>
      <v-btn
        @click="confirmWithoutPhotos"
        type="submit"
      >
        Confirma
      </v-btn>
    </v-card-actions>
    <router-view
      :inventory="inventory"
      :scriptic-item="scripticItem"
    />
  </div>
</template>
<script lang="ts">
import { computed, inject, reactive, toRefs, defineComponent } from 'vue';
import { map } from 'rambda';
import api from '@/api';
import ScripticItemPreview from '@/pages/active-inventory/scan/confirm/ScripticItemPreview.vue';
import ScripticItemLocation from '@/pages/active-inventory/scan/confirm/ScripticItemLocation.vue';

export default defineComponent({
  components: {
    ScripticItemPreview,
    ScripticItemLocation,
  },
  props: {
    node: {
      type: Object,
      required: true,
    },
    scripticItem: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const $msg = inject('msg');
    const router = inject('router');
    const store = inject('store');

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const fields = {
      qty: {
        label: 'Cantitate',
        cast: (v: any) => parseInt(v),
        default: () => {
          if (!state.isQty) {
            return '';
          }

          if (!state.scan) {
            return '0';
          }

          return state.scan.qty.toString();
        },
        rules: [
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          (v) => !state.isQty.value || !!v || 'Cantitatea este obligatorie.',
          (v: any) => parseInt(v).toString() === v || 'Cantitatea trebuie sa fie un numar intreg.',
          (v: any) => v >= 1 || 'Cantitatea trebuie sa fie mai mare sau egala cu 1.',
        ],
      },
      condition: {
        label: 'Conditie',
        default: null,
        rules: [],
        items: [
          {
            text: 'Bun',
            value: 'good',
          },
          {
            text: 'Acceptabil',
            value: 'acceptable',
          },
          {
            text: 'Nefunctional',
            value: 'faulty',
          },
        ],
      },
      conditionDescription: {
        label: 'Descriere conditie',
        default: null,
        rules: [],
      },
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const state = reactive({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      inventory: computed(() => store.state.activeInventory.inventory),
      isQty: computed(() => props.scripticItem.importAsQty),
      scan: computed(() => props.scripticItem.scan.find((scan: any) => scan.nodeId === props.node.id)),
      showPhotos: false,
      showQuantities: false,
      showScans: false,
      submitting: false,
      location: {
        active: false,
        location: null,
      },
      fields,
      errors: map(() => [], fields),
      form: undefined,
    });

    state.form = map(({ default: value }: any) => (value instanceof Function ? value() : value), fields);

    const confirm = () => {
      state.submitting = true;

      return api.inventory.scan
        .confirm({
          params: { inventoryId: state.inventory._id },
          body: {
            ...state.form,
            nodeId: props.node.id,
            itemId: props.scripticItem._id,
            coordinates: state.location.location,
          },
        })
        .then((response) =>
          response.json().then((payload) => {
            if (response.ok) {
              return payload.data;
            } else if (response.status === 422) {
              Object.keys(state.errors).forEach((field) => {
                state.errors[field] = payload.errors.hasOwnProperty(field) ? payload.errors[field].slice(0) : [];
              });
              return false;
            } else {
              return Promise.reject(new Error(payload.error));
            }
          }),
        )
        .finally(() => {
          state.submitting = false;
        });
    };
    const confirmWithPhotos = () =>
      confirm()
        .then((scan) => {
          if (scan) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            router.push({
              name: 'active-inventory.confirm.photos',
            });
          }
        })
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .catch((e) => $msg(e));

    const confirmWithoutPhotos = () =>
      confirm()
        .then((scan) => {
          if (scan) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            $msg({ color: 'success', title: 'Confirmat.' });
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            router.push({ name: 'active-inventory.scan' });
          }
        })
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .catch((e) => $msg(e));

    return {
      confirmWithPhotos,
      confirmWithoutPhotos,
      ...toRefs(state),
    };
  },
});
</script>
