<template>
  <v-row
    v-if="loading"
    justify="center"
  >
    <v-progress-circular
      size="120"
      width="5"
      indeterminate
    />
  </v-row>
  <router-view
    v-else-if="scripticItem"
    v-bind="$props"
    :scriptic-item="scripticItem"
  />
  <v-alert
    v-else
    color="error"
  >
    Nu am putut incarca...
  </v-alert>
</template>
<script lang="ts">
import api from '@/api';
import { computed, inject, reactive, toRefs, watch, defineComponent } from 'vue';

export default defineComponent({
  props: {
    node: { type: Object, required: true },
    itemId: { type: String, required: true },
  },
  setup(props) {
    const store = inject('store');

    const state = reactive({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      inventory: computed(() => store.state.activeInventory.inventory),
      loading: true,
      scripticItem: undefined,
    });

    const setSuccess = (item: any) => {
      state.scripticItem = item;
    };

    const load = (itemId: any) =>
      api.inventory.scriptic.items
        .show({
          params: { inventoryId: state.inventory._id, itemId },
        })
        .then((response) => response.json().then((payload) => (response.ok ? setSuccess(payload.data) : null)))
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .catch((e) => this.$msg(e))
        .finally(() => {
          state.loading = false;
        });

    watch(() => props.itemId, load, { immediate: true });

    return toRefs(state);
  },
});
</script>
