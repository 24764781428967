export const hasProperty = (obj: any, prop: any) => {
  let currentObj = obj;

  return prop.split('.').every((prop: any) => {
    if (currentObj.hasOwnProperty(prop) === false) {
      return false;
    }

    currentObj = currentObj[prop];
    return true;
  });
};

export const isString = (s: any) => typeof s === 'string' || s instanceof String;

export const isObject = (o: any) => o !== undefined && o !== null && Object.prototype === Object.getPrototypeOf(o);

export const isPromise = (p: any) => p !== null && Promise.resolve(p) === p;

export const isFn = (fn: any) => fn && fn instanceof Function;

export const ObjectId = () => {
  const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
  return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, () => ((Math.random() * 16) | 0).toString(16)).toLowerCase();
};

export const handleResponsePayload =
  (ret: any, extras = {}) =>
  (res: any) =>
    res.json().then((payload: any) => {
      if (res.ok) {
        return ret instanceof Function ? ret(payload.data) : ret;
      }

      if (extras.hasOwnProperty(res.status)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return extras(payload);
      }

      return Promise.reject(new Error(payload.error));
    });

export const rkey = () => ({ rkey: new Date().getTime().toString() });
