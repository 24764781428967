/* eslint-disable no-console */
/// <reference lib="WebWorker" />

import { register } from 'register-service-worker';
import store from '@/store';

declare const self: ServiceWorkerGlobalScope;

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      self.addEventListener('fetch', function (event) {
        event.respondWith(
          (async function () {
            const cache = await caches.open('cache');
            const cachedResponsePromise = await cache.match(event.request);
            const networkResponsePromise = fetch(event.request);
            event.waitUntil(
              (async function () {
                const networkResponse = await networkResponsePromise;

                await cache.put(event.request, networkResponse.clone());
              })(),
            );
            return cachedResponsePromise || networkResponsePromise;
          })(),
        );
      });
      console.log('App is being served from cache by a service worker.');
    },
    registered() {
      console.log('Service worker has been registered.');
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      store.dispatch('messages/push', { color: 'info', title: 'Se descarca actualizari...' });
    },
    updated() {
      store.dispatch('messages/push', { color: 'info', title: 'Reincarca pentru a aplica actualizarile' });
    },
    offline() {
      store.dispatch('messages/push', { color: 'warning', title: 'Nu exista conexiune la retea.' });
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
