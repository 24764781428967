import { prepTree } from '@/lib/trees';
import api from '@/api';

export default {
  namespaced: true,
  state: {
    loading: false,
    promise: undefined,
    inventory: undefined,
    error: null,
  },
  mutations: {
    startLoading: (state: any, promise: any) => {
      state.promise = promise;
      state.loading = true;
      state.inventory = undefined;
      state.error = null;
    },
    setInventory: (state: any, inventory: any) => {
      state.inventory = {
        ...inventory,
        organizationalChart: undefined,
      };
    },
    setOrgChart: (state: any, data: any) => {
      state.inventory.organizationalChart = prepTree(data);
    },
    finishLoading: (state: any, error: any) => {
      if (error instanceof Error) {
        state.error = error;
      }
      state.loading = false;
    },
  },
  actions: {
    load: ({ state, commit }: any) => {
      if (state.loading) {
        return state.promise;
      }

      commit('startLoading', api.inventory.active());

      return state.promise
        .then((response: any) =>
          response
            .json()
            .then((payload: any) =>
              response.ok ? commit('setInventory', payload.data) : Promise.reject(new Error(payload.error)),
            ),
        )
        .then(() => {
          if (state.inventory.organizationalChartId) {
            return api.inventory
              .orgChart({ params: { inventoryId: state.inventory._id } })
              .then((response) =>
                response
                  .json()
                  .then((payload) =>
                    response.ok ? commit('setOrgChart', payload.data) : Promise.reject(new Error(payload.error)),
                  ),
              );
          }
        })
        .then(() => commit('finishLoading'))
        .catch((e: any) => commit('finishLoading', e));
    },
  },
};
