import Vue from 'vue';
Vue.component('form-label', {
  name: 'FieldsLabel',
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  render(h) {
    if (this.field.rules.required) {
      return h('span', [this.field.label + ' *']);
    } else {
      return h('span', [this.field.label]);
    }
  },
});
