import Vue, { watch } from 'vue';
import './registerServiceWorker';
import vuetify from '@/plugins/vuetify';
import router from '@/router';
import store from '@/store';
import * as filters from '@/lib/filters';
import pageHeader from '@/components/pages';
import './components/form/index';
import errorHandler from '@/components/errorHandler';
import '@/assets/style.scss';
import { VProgressCircular } from 'vuetify/lib';

Vue.use(errorHandler);
Vue.use(pageHeader);

Vue.config.productionTip = false;

const loading = store.dispatch('auth/initialize').then(() => {
  watch(
    () => store.state.auth.token,
    (current, prev) => {
      if (typeof current === 'string' && !prev) {
        if (router.currentRoute.name === 'login') {
          router.push({
            name: 'home',
          });
        }
      } else if (!current && !prev) {
        router.push({ name: 'login' });
      } else if (!current) {
        window.location.reload();
      }
    },
    { immediate: true },
  );
});

const msg: MessageLogger = (_msg: any) => store.dispatch('messages/push', _msg);

router.beforeEach(async (to, from, next) => {
  await loading;

  const isGuest = store.getters['auth/isGuest'];
  if (isGuest && to.matched.some((route) => route.meta.auth === true)) {
    return next({ name: 'login' });
  }

  if (!isGuest && to.name === 'login') {
    return next({ name: 'home' });
  }

  return next();
});

router.onError(msg);

const pageDeps = (dep: string, value: any) => store.commit('pageDeps/set', { dep, value });

store.dispatch('system/start');

new Vue({
  name: 'App',
  router,
  store,
  vuetify,
  provide: {
    ...filters,
    store,
    router,
    pageDeps,
    msg,
  },
  render: (h) => {
    return store.state.auth.initializing
      ? h(
          'div',
          {
            style: { margin: '3em', textAlign: 'center' },
          },
          [
            h(VProgressCircular, {
              props: {
                size: 200,
                width: 5,
                color: 'primary',
                indeterminate: '',
              },
            }),
          ],
        )
      : h('router-view');
  },
}).$mount('#app');
