import Main from '@/pages/active-inventory/scan/confirm/Main.vue';
import Confirm from '@/pages/active-inventory/scan/confirm/Confirm.vue';
import ScripticItemPhotos from '@/pages/active-inventory/scan/confirm/ScripticItemPhotos.vue';

export default {
  path: 'confirm/:itemId',
  props: true,
  component: Main,
  children: [
    {
      path: '',
      name: 'active-inventory.confirm',
      component: Confirm,
    },
    {
      path: 'photos',
      name: 'active-inventory.confirm.photos',
      component: ScripticItemPhotos,
    },
  ],
};
