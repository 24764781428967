<template>
  <v-row
    v-if="state.loading"
    justify="center"
  >
    <v-progress-circular
      size="120"
      width="5"
      indeterminate
    />
  </v-row>
  <router-view v-else-if="state.inventory" />
  <uv-alert-icon
    v-else
    type="error"
  >
    {{ state.error ? state.error.message : 'Eroare la incarcare.' }}
  </uv-alert-icon>
</template>
left
<script lang="ts">
import { reactive, inject, watch, defineComponent } from 'vue';

export default defineComponent({
  setup() {
    const store = inject('store');
    const pageDeps = inject('pageDeps');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const state = reactive(store.state.activeInventory);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    store.dispatch('activeInventory/load');

    watch(
      () => state.inventory,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (inventory) => pageDeps('activeInventory', inventory),
      { immediate: true },
    );

    return {
      state,
    };
  },
});
</script>
