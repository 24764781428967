import Vue from 'vue';
import Vuex from 'vuex';
import { RootState } from '@/store/definitions';
import activeInventory from '@/store/activeInventory';
import { module as auth } from '@/store/auth';
import messages from '@/store/messages';
import pageDeps from '@/store/pageDeps';
import { module as location, plugin as locationPlugin } from '../plugins/location';
import { module as system } from '@/store/system';

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
  modules: {
    activeInventory,
    auth,
    location,
    messages,
    pageDeps,
    system,
  },
  plugins: [locationPlugin],
});
