export const map = {
  url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
  maxBounds: [
    [48.37, 19.183],
    [43.333, 30.156],
  ],
  center: [45.9432, 24.9668],
  minZoom: 6,
  padding: [0.5, 0.5],
};

export const formats = {
  dateTime: 'dd.LL.yyyy, HH:m',
  date: 'yyyy-LL-dd',
  dateAlt: 'dd.LL.yyyy',
};

export const bool = {
  yes: 'Da',
  no: 'Nu',
};
