<template>
  <div>
    <v-app-bar
      flat
      dark
      color="primary"
    >
      <v-btn
        :to="{ name: 'active-inventory.show' }"
        exact
        icon
      >
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <h3>Scaneaza</h3>
    </v-app-bar>
    <div id="reader" />
    <v-form
      v-model.trim="valid"
      @submit.prevent="create"
    >
      <v-text-field
        v-model.trim="form.code"
        @keypress.enter.prevent="enter"
        ref="code"
        :required="fields.code.rules.required"
        :error-messages="errors.code"
        :label="labels.code"
        :loading="submitting"
        class="scan-code"
        style="font-size: 2em"
      >
        <template #append>
          <v-btn
            @click="scan"
            color="primary"
            icon
          >
            <v-icon large>qr_code_scanner</v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </v-form>
  </div>
</template>
<script lang="ts">
import api from '@/api';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import makeValidator from '@/lib/validator';
import { Html5Qrcode } from 'html5-qrcode';
import { defineComponent } from 'vue';

const makeScanner = (id: any) => {
  const scanner = new Html5Qrcode(id);

  return () =>
    new Promise((resolve, reject) => {
      scanner
        .start(
          { facingMode: 'environment' },
          {
            fps: 10,
            qrbox: { width: 250, height: 250 },
          },
          (decodedText, _decodedResult: any) => {
            scanner.stop();
            resolve(decodedText);
          },
          (_errorMessage: any) => {
            // there's nothing to do with this info
          },
        )
        .catch((err: any) => {
          reject(err);
        });
    });
};

const fields = {
  code: {
    label: 'Numar de inventar',
    default: '',
    rules: { required },
  },
};

export default defineComponent({
  name: 'InventoryScanCode',
  mixins: [validationMixin],
  props: {
    node: { type: Object, required: true },
  },
  data() {
    return {
      scanner: null,
      submitting: false,
      valid: true,
      backendErrors: undefined,
      scripticItem: undefined,
      createItemModal: false,
      scripticItemConfirmationModal: false,
      scripticItemPhotosModal: false,
      form: Object.entries(fields).reduce(
        (carry, [field, { default: value }]: any) => ({ ...carry, [field]: value }),
        {},
      ),
      fields,
    };
  },
  validations() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.validator.validator();
  },
  computed: {
    validator() {
      return makeValidator(fields, 'form');
    },
    errors() {
      return this.validator.errors(this.$v, this.backendErrors);
    },
    labels() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.validator.labels();
    },
    inventory() {
      return this.$store.state.activeInventory.inventory;
    },
  },
  mounted() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.scanner = makeScanner('reader');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$refs.code.focus();
  },
  methods: {
    scan() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.scanner()
        .then((result: any) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.form.code = result;
        })
        .then(() => this.find())
        .catch((e: any) => this.$msg(e));
    },
    enter() {
      return this.find().catch((e: any) => this.$msg(e));
    },
    find() {
      if (this.submitting) {
        return Promise.resolve();
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        return Promise.resolve();
      }
      this.submitting = true;
      const body = this.validator.values(this.$v);
      this.backendErrors = undefined;

      return api.inventory.scan
        .find({
          params: { inventoryId: this.inventory._id },
          body,
        })
        .then((response) =>
          response.json().then((payload: any) => {
            if (response.ok) {
              if (payload.data) {
                this.$router.push({
                  name: 'active-inventory.confirm',
                  params: { itemId: payload.data._id },
                });
              } else {
                this.$msg(new Error('Reper neidentificat.'));
                // this.$router.push({
                //   name: 'active-inventory.create',
                //   params: { code: this.form.code }
                // });
              }
            } else if (response.status === 422) {
              this.backendErrors = payload.errors;
            } else {
              return Promise.reject(new Error(payload.error));
            }
          }),
        )
        .finally(() => {
          this.submitting = false;
        });
    },
  },
});
</script>
<style>
#scan-code .v-input__append-inner {
  margin-top: 0;
}
</style>
