<template>
  <v-progress-linear
    v-if="map.loading"
    indeterminate
  />
  <v-card-text v-else>
    <v-alert
      v-if="location.error"
      color="error"
      dark
    >
      {{ location.error.message }}
    </v-alert>
    <v-switch
      v-model="location.auto"
      :loading="location.locating"
      label="Automat"
    />
    <l-map
      @ready="mapReady"
      @click="mapClicked"
      ref="mapRef"
      style="height: 450px"
      :min-zoom="map.minZoom"
      :max-bounds="map.bounds"
      :center="map.center"
    >
      <l-tile-layer :url="map.url" />
      <l-polygon
        ref="polygon"
        :lat-lngs="map.bounds"
        :fill="false"
      />
      <template v-if="map.marker">
        <l-circle
          v-if="map.showCircle"
          :lat-lng="map.marker"
          :radius="map.marker.accuracy"
          :stroke="false"
          :fill-opacity="0.1"
          fill-color="#ff0055"
          z-index="-1"
        />
        <l-marker
          @update:lat-lng="markerDragged"
          :lat-lng="map.marker"
          draggable
        />
      </template>
    </l-map>
  </v-card-text>
</template>
<script lang="ts">
import { computed, reactive, watch, defineComponent, ref } from 'vue';
import { map as mapConfig } from '@/config/ro';
import { getPolygonCenter } from '@/lib/coordinates';

const geolocate = () =>
  new Promise((resolve, reject) =>
    window.navigator.geolocation.getCurrentPosition(resolve, reject, {
      timeout: 10000,
      enableHighAccuracy: true,
    }),
  );

const fromGeoJSON = (poly: any) => poly.coordinates[0].map((latlng: any) => latlng.slice().reverse());

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
    },
    bounds: {
      type: Object,
      required: true,
    },
    scripticItem: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const location = reactive({
      show: false,
      locating: false,
      auto: true,
      maxAccuracy: 25,
      location: null,
      error: null,
    });
    const mapRef = ref<HTMLElement>();
    const map = reactive({
      ...mapConfig,
      loading: true,
      object: null,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      showCircle: computed(() => location.location && location.location.coords.accuracy > location.maxAccuracy),
      bounds: computed(() => fromGeoJSON(props.bounds)),
      center: computed(() => {
        const [lat, lng] = getPolygonCenter(map.bounds);

        return { lat, lng };
      }),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      marker: computed(() => (location.location ? location.location.coords : undefined)),
    });

    const locate = () => {
      location.locating = true;
      location.location = null;
      location.error = null;

      return geolocate()
        .then((position: any) => {
          if (position.coords.accuracy > location.maxAccuracy) {
            return Promise.reject(
              new Error(`Acuratetea este prea mica (${position.coords.accuracy}m). Mai incearca o data.`),
            );
          }
          return moveMarker(
            { lat: position.coords.latitude, lng: position.coords.longitude },
            parseInt(position.coords.accuracy),
            position.timestamp,
          );
        })
        .catch((error) => {
          location.error = error;

          return Promise.reject(error);
        })
        .finally(() => {
          location.locating = false;
        });
    };

    const mapClicked = ({ latlng }: any) => {
      moveMarker(latlng, 1, new Date().getTime());
      location.auto = false;
    };

    const markerDragged = (latlng: any) => {
      moveMarker(latlng, 1, new Date().getTime());
      location.auto = false;
    };

    const moveMarker = (latlng: any, accuracy = 1, ts: any = undefined) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      location.location = {
        coords: {
          ...latlng,
          accuracy,
        },
        timestamp: ts || new Date().getTime(),
      };
    };

    watch(
      () => location.auto,
      (auto) => {
        if (auto) {
          locate()
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .then(() => mapRef?.value?.mapObject?.fitBounds(map.bounds))
            .catch((e) => {
              location.auto = false;
            });
        } else if (!location.location) {
          moveMarker(map.center);
        }
      },
      { immediate: true },
    );

    watch(
      () => location.location,
      (l) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        emit('input', l ? l.coords : null);
      },
    );

    import('@/plugins/leaflet' /* webpackChunkName: 'leaflet' */).finally(() => {
      map.loading = false;
    });

    const mapReady = (o: any) => {
      map.object = o;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      map.object.fitBounds(map.bounds);
    };

    return { confirm, map, locate, location, markerDragged, mapClicked, mapReady, mapRef };
  },
});
</script>
