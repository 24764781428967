export default {
  install(Vue: any, options: any) {
    Vue.prototype.$pageDeps = function (dep: any, value: any) {
      this.$store.commit('pageDeps/set', {
        dep,
        value,
      });
    };
  },
};
